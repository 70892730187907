"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Location_1 = require("./Location");
var Space_1 = require("./Space");
var Entity_1 = require("./Entity");
var Ajax_1 = require("../util/Ajax");
var User_1 = require("./User");
var Formatting_1 = require("../util/Formatting");
var Booking = /** @class */ (function (_super) {
    __extends(Booking, _super);
    function Booking() {
        var _this = _super.call(this) || this;
        _this.enter = new Date();
        _this.leave = new Date();
        _this.location = new Location_1.default();
        _this.space = new Space_1.default();
        _this.user = new User_1.default();
        return _this;
    }
    Booking.prototype.serialize = function () {
        // Convert the local dates to UTC dates without changing the date/time ("fake" UTC)
        var enter = Formatting_1.default.convertToFakeUTCDate(this.enter);
        var leave = Formatting_1.default.convertToFakeUTCDate(this.leave);
        return Object.assign(_super.prototype.serialize.call(this), {
            "enter": enter.toISOString(),
            "leave": leave.toISOString(),
            "spaceId": this.space.id,
        });
    };
    Booking.prototype.deserialize = function (input) {
        _super.prototype.deserialize.call(this, input);
        // Discard time zone information from date
        input.enter = Formatting_1.default.stripTimezoneDetails(input.enter);
        input.leave = Formatting_1.default.stripTimezoneDetails(input.leave);
        this.enter = new Date(input.enter);
        this.leave = new Date(input.leave);
        if (input.space) {
            this.space.deserialize(input.space);
        }
        if (input.userId) {
            this.user.id = input.userId;
        }
        if (input.userEmail) {
            this.user.email = input.userEmail;
        }
    };
    Booking.prototype.getBackendUrl = function () {
        return "/booking/";
    };
    Booking.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.saveEntity(this, this.getBackendUrl()).then(function () { return _this; })];
            });
        });
    };
    Booking.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.delete(this.getBackendUrl() + this.id).then(function () { return undefined; })];
            });
        });
    };
    Booking.get = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get("/booking/" + id).then(function (result) {
                        var e = new Booking();
                        e.deserialize(result.json);
                        return e;
                    })];
            });
        });
    };
    Booking.list = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get("/booking/").then(function (result) {
                        var list = [];
                        result.json.forEach(function (item) {
                            var e = new Booking();
                            e.deserialize(item);
                            list.push(e);
                        });
                        return list;
                    })];
            });
        });
    };
    Booking.listFiltered = function (start, end) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                payload = {
                    start: start,
                    end: end
                };
                return [2 /*return*/, Ajax_1.default.postData("/booking/filter/", payload).then(function (result) {
                        var list = [];
                        result.json.forEach(function (item) {
                            var e = new Booking();
                            e.deserialize(item);
                            list.push(e);
                        });
                        return list;
                    })];
            });
        });
    };
    Booking.createFromRawArray = function (arr) {
        return arr.map(function (booking) {
            var res = new Booking();
            res.deserialize(booking);
            return res;
        });
    };
    return Booking;
}(Entity_1.Entity));
exports.default = Booking;
